import React from 'react';
// import { Link } from 'gatsby';
import { Router } from '@reach/router';
import { view } from 'react-easy-state';
import styled from '@emotion/styled';
import queryString from 'query-string';
import { format } from 'date-fns';
// import { Lightbox } from 'react-modal-image';

import store from '../stores';
import Layout from '../components/layout';

const Download = styled.div`
  ${tw`absolute z-20 pin flex justify-center items-center`}
  opacity: 0;
  transition: opacity 0.2s ease;
`;

const Column = styled.div`
  ${tw`w-full sm:w-1/2 lg:w-1/3`};

  &:hover {
    ${Download} {
      opacity: 1;
    }
  }
`;

@view
class SearchRoute extends React.Component {
  state = {
    lightboxOpen: false,
    lightboxImage: null,
    lightboxAlt: null
  }

  async componentDidMount() {
    const { q, c } = queryString.parse(this.props.location.search);
    store.keyword = q;
    store.countryCode = c;
    await store.fetchArtworks();
  }

  async componentDidUpdate() {
    if (store.queryString !== this.props.location.search) {
      store.queryString = this.props.location.search;
      const { q, c } = queryString.parse(this.props.location.search);
      store.keyword = q;
      store.countryCode = c;
      await store.fetchArtworks();
    }
  }

  openLightbox = (lightboxImage, lightboxAlt) => {
    window.open(lightboxImage, '_blank');
    // this.setState({
    //   lightboxOpen: true,
    //   lightboxImage,
    //   lightboxAlt
    // });
  }

  // closeLightbox = () => {
  //   this.setState({
  //     lightboxOpen: false,
  //     lightboxImage: null,
  //     lightboxAlt: null
  //   });
  // }

  render() {
    const { artworks } = store;
    // const { lightboxOpen, lightboxImage, lightboxAlt } = this.state;
    // console.log(artworks[0]);

    return (
      <>
        {/* {lightboxOpen && (
          <Lightbox
            large={lightboxImage}
            alt={lightboxAlt}
            onClose={this.closeLightbox}
          />
        )} */}
        {artworks && (
          <div css={tw`flex flex-wrap -mx-2`}>
            {artworks.map(artwork => (
              <Column key={`${artwork.collectionId}-${artwork.trackId}`}>
                <div className="square-box">
                  <div className="square-box-inner">
                    <div css={tw`absolute pin z-0 m-2 rounded overflow-hidden`}>
                      <div
                        css={tw`absolute pin z-0`}
                        style={{
                          background: `url(${artwork.artwork.standard})`,
                          backgroundSize: 'cover',
                        }}
                      >
                        <div
                          css={tw`absolute pin`}
                          style={{
                            background: 'linear-gradient(to top, black, transparent)',
                            opacity: 0.8,
                          }}
                        />
                      </div>

                      <div css={tw`absolute z-10 pin-b pin-l p-4 text-white`}>
                        <h4 css={tw`font-hairline text-sm`}>{artwork.artistName}</h4>
                        <h2 css={tw`leading-none`}>
                          {artwork.collectionName}{' '}
                          <span css={tw`font-thin opacity-75`}>
                            {format(artwork.releaseDate, 'YYYY')}
                          </span>
                        </h2>
                      </div>

                      <Download>
                        <div css={tw`absolute pin z-0 bg-black opacity-75`} />
                        <div css={tw`relative z-10`}>
                          <div css={tw`text-white text-xs font-bold uppercase mb-2 text-center`}>Download</div>
                          <button
                            type="button"
                            css={tw`uppercase bg-transparent hover:bg-brand text-brand font-black hover:text-white py-1 px-2 border-2 border-brand hover:border-transparent rounded mr-2`}
                            onClick={() => this.openLightbox(artwork.artwork.standard, `${artwork.artistName} - ${artwork.collectionName}`)}
                          >
                            Standard
                          </button>
                          <button
                            type="button"
                            css={tw`uppercase bg-transparent hover:bg-brand text-brand font-black hover:text-white py-1 px-2 border-2 border-brand hover:border-transparent rounded`}
                            onClick={() => this.openLightbox(artwork.artwork.hires, `${artwork.artistName} - ${artwork.collectionName}`)}
                          >
                            Hi-Res
                          </button>
                        </div>
                      </Download>
                    </div>
                  </div>
                </div>
              </Column>
            ))}
          </div>
        )}
      </>
    );
  }
}

class SearchPage extends React.Component {
  render() {
    return (
      <Layout>
        <Router>
          <SearchRoute path="/search" />
        </Router>
      </Layout>
    );
  }
}

export default view(SearchPage);
